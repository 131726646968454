
import { defineComponent, ref, computed } from 'vue'
import { ElButton, ElIcon } from 'element-plus'
import { Edit, Key } from '@element-plus/icons-vue'

import { EPermissions } from '@/types'
import { localeDate } from '@/core/utils'
import { authStore } from '@/store'

import UpdateModal from './components/UpdateModal.vue'
import UpdatePasswordModal from './components/UpdatePasswordModal.vue'

export default defineComponent({
  name: 'ProfileView',

  components: {
    ElButton,
    ElIcon,
    Edit,
    Key,
    UpdateModal,
    UpdatePasswordModal,
  },

  setup() {
    const loading = ref(false)
    const updateModalVisible = ref(false)
    const updatePasswordModalVisible = ref(false)

    const user = computed(() => {
      return authStore.user
    })

    const openUpdateModal = () => {
      updateModalVisible.value = true
    }
    const closeUpdateModal = (refreshUserData: boolean) => {
      updateModalVisible.value = false

      if (refreshUserData) {
        authStore.getUser()
      }
    }

    const openUpdatePasswordModal = () => {
      updatePasswordModalVisible.value = true
    }
    const closeUpdatePasswordModal = () => {
      updatePasswordModalVisible.value = false
    }

    return {
      loading,
      localeDate,
      user,
      updateModalVisible,
      openUpdateModal,
      closeUpdateModal,
      EPermissions,
      openUpdatePasswordModal,
      closeUpdatePasswordModal,
      updatePasswordModalVisible,
    }
  },
})
