import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_ElButton = _resolveComponent("ElButton")!
  const _component_ElDialog = _resolveComponent("ElDialog")!

  return (_openBlock(), _createBlock(_component_ElDialog, {
    "model-value": _ctx.modelValue,
    title: "Update profile password",
    width: "450px",
    "close-on-click-modal": !_ctx.loading,
    "close-on-press-escape": !_ctx.loading,
    "show-close": !_ctx.loading,
    onOpen: _ctx.onOpen,
    onClose: _ctx.onClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_ElButton, {
          onClick: _ctx.onClose,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_ElButton, {
          type: "primary",
          loading: _ctx.loading,
          onClick: _ctx.onSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode("Update")
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ElForm, {
        ref: "formRef",
        model: _ctx.formValues,
        rules: _ctx.formValidationRules,
        "label-position": "top",
        class: "w-full",
        "status-icon": "",
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElFormItem, {
            label: "Password",
            prop: "password",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: _ctx.formValues.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValues.password) = $event)),
                modelModifiers: { trim: true },
                class: "w-full",
                size: "large",
                type: "password",
                "show-password": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ElFormItem, {
            label: "New password",
            prop: "newPassword",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: _ctx.formValues.newPassword,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValues.newPassword) = $event)),
                modelModifiers: { trim: true },
                class: "w-full",
                size: "large",
                type: "password",
                "show-password": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ElFormItem, {
            label: "Confirm new password",
            prop: "confirmNewPassword",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElInput, {
                modelValue: _ctx.formValues.confirmNewPassword,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValues.confirmNewPassword) = $event)),
                modelModifiers: { trim: true },
                class: "w-full",
                size: "large",
                type: "password",
                "show-password": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules", "disabled"])
    ]),
    _: 1
  }, 8, ["model-value", "close-on-click-modal", "close-on-press-escape", "show-close", "onOpen", "onClose"]))
}